<template>
  <div>
    <div id="particles-js"></div>
    <div class="content">
      <RouterView />
    </div>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
export default {
  name: "EmptyLayout",
  mounted() {
    AOS.init({
      disable: function () {
        var maxWidth = 1200;
        return window.innerWidth < maxWidth;
      },
      duration: 800,
    });
  },
};
</script>

<style scoped lang="scss">
#particles-js {
  position: absolute;
  width: 100%;
  height: 100%;
  line-height: 0;
  background-color: transparent;
}
.content {
  position: relative;
  z-index: 1;
}
</style>
