const fr = {
  btn: {
    registration: "Inscription",
    openAccount: "Compte ouvert",
    join: "Rejoindre",
    login: "Entrer",
    register: "Enregistrer",
    continue: "Continuer",
    begin: "Commencer à trader",
    more: "Plus de détails",
    confirm: "Confirmer",
  },
  menu: {
    home: "Maison",
    markets: "Marchés",
    about: "À propos de nous",
    contacts: "Contacts",
    platform: "Plateforme",
    faq: "FAQ",
    safe: "Sécurité",
  },
  subMenu: {
    commodities: "Marchandises",
    forex: "Forex",
    indices: "Indices",
    stock: "Action",
    currency: "Crypto-monnaies",
  },
  form: {
    name: "Nom",
    phone: "Numéro de téléphone",
  },
  privacy: "Politique de confidentialité",
  terms: "Termes et conditions",
  assets: "Actifs",
  support: "Soutien",
  leverageTo: "Épaule levée",
  depositFrom: "Dépôt de",
  cities: ["Großbritannien", "Zypern", "Hongkong"],
  popularAssets: "Actifs populaires",
  worksDay1: "Lun-Ven",
  worksDay2: "Sam Dim",
  ourAddress: "Notre adresse",
  company: "Entreprise",
};

export default fr;
