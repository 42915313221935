const en = {
  btn: {
    registration: "Registration",
    openAccount: "Open Account",
    join: "Join",
    login: "Login",
    register: "Register",
    continue: "Continue",
    begin: "Start trading",
    more: "More details",
    confirm: "Confirm",
  },
  menu: {
    home: "Home",
    markets: "Markets",
    about: "About Us",
    contacts: "Contacts",
    platform: "Platform",
    faq: "FAQ",
    safe: "Safety",
  },
  subMenu: {
    forex: "Forex",
    indices: "Indices",
    stock: "Stock",
    currency: "Cryptocurrencies",
    commodities: "Commodities",
  },
  form: {
    name: "Name",
    phone: "Phone number",
  },
  privacy: "Privacy Policy",
  terms: "Terms and Conditions",
  assets: "Assets",
  support: "Support",
  leverageTo: "Shoulder up",
  depositFrom: "Deposit from",
  cities: ["Great Britain", "Cyprus", "Hong Kong"],
  popularAssets: "Popular assets",
  worksDay1: "Mon-Fri",
  worksDay2: "Sat-Sun",
  ourAddress: "Our address",
  company: "Company",
};

export default en;
