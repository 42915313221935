<template>
  <div class="lang-switcher" :class="{ 'lang-switcher_open': isOpened }">
    <button
      type="button"
      class="btn btn-md btn-white lang-switcher__btn"
      @click="changeSwitcherState()"
    >
      <span>{{ currentLanguage.code }}</span>
      <span class="icon-arrow-down dropdown-icon"></span>
    </button>
    <div class="lang-switcher__dropdown" v-if="isOpened">
      <span
        class="lang-switcher__item"
        v-for="lang in listLanguages"
        @click="changeLocale(lang.code)"
        :key="lang.code"
      >
        {{ lang.code }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "LanguageSwitcher",
  data: function () {
    return {
      isOpened: false,
      languages: [{ code: "de" }, { code: "en" }, { code: "fr" }],
    };
  },
  mounted() {
    document.addEventListener("click", this.clickHandler);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.clickHandler);
  },
  methods: {
    clickHandler(e) {
      if (!this.$el.contains(e.target)) {
        this.isOpened = false;
      }
    },
    changeSwitcherState() {
      this.isOpened = !this.isOpened;
    },
    changeLocale(locale) {
      this.$i18n.locale = locale;
      this.$router.replace(this.$switchLocalePath(locale));
    },
  },
  computed: {
    currentLanguage() {
      return this.languages.find((item) => item.code === this.$i18n.locale);
    },
    listLanguages() {
      return this.languages.filter(
        (item) => item.code !== this.currentLanguage.code
      );
    },
  },
};
</script>

<style scoped lang="scss">
.lang-switcher {
  position: relative;

  &.lang-switcher_open {
    .dropdown-icon {
      transform: rotate(180deg);
    }
  }
}
.dropdown-icon {
  font-size: 10px;
}
.lang-switcher__btn {
  padding-left: 14px;
  padding-right: 14px;
  font-size: 16px;
  transition: all 0.2s ease-in-out;
  text-transform: uppercase;
  gap: 5px;
  border-color: transparent;

  span {
    line-height: 1;
  }
}

.lang-switcher__dropdown {
  position: absolute;
  top: 57px;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  background: $white;
  box-shadow: 0 0 30px rgba(125, 49, 234, 0.05);
  border-radius: 0 0 5px 5px;
}

.lang-switcher__item {
  text-align: center;
  width: 100%;
  cursor: pointer;
  text-transform: uppercase;

  &:hover {
    background: rgba($orange-light, 0.1);
  }
}

@include media-breakpoint-down(xl) {
  .lang-switcher__dropdown {
    top: 57px;
  }
}
</style>
